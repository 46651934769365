import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import axiosClient from '../../../apiClient/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';

const SpmDevice = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeviceInfo, setIsDeviceInfo] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    const getDeviceStatus = async () => {
      // setIsLoading(true);
      try {
        const { data } = await axiosClient.fetchSingleDeviceStatus(params.id);
        console.log(data);
        setDeviceInfo(data);
        setIsDeviceInfo(true);
        //   setIsLoading(false);
      } catch (err) {
        console.log(err);
        //   setIsLoading(false);
        if (err.response) {
          alert(err.response?.data?.message);
          navigate('/');
        }
      }
    };
    getDeviceStatus();
  }, []);

  const runMotor = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.sendCommand(params.id, {
        cmd: 'm_start',
      });
      console.log(data);
      setDeviceInfo(data.device_status);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const switchOffMoter = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.sendCommand(params.id, {
        cmd: 'm_stop',
      });
      console.log(data);
      setDeviceInfo(data.device_status);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className={style.container}>
        {isDeviceInfo ? (
          <>
            <div
              className={style.deviceStatus}
              style={
                deviceInfo.is_motor_running
                  ? { backgroundColor: 'green', color: 'white' }
                  : { backgroundColor: '#F0F0F0' }
              }
            >
              <p>
                Motor is{' '}
                {deviceInfo.is_motor_running ? 'running' : 'not running'}
              </p>
            </div>
            <div>
              <p className={style.title}>Motor Informations</p>
              <div className={style.deviceInfoCardsContainer}>
                <div className={style.deviceInfoCard}>
                  <p className={style.title}>Network Strength</p>
                  <p className={style.value}>{deviceInfo.network}&#37; </p>
                </div>
                <div className={style.deviceInfoCard}>
                  <p className={style.title}>Battery</p>
                  <p className={style.value}>{deviceInfo.battery}&#37; </p>
                </div>
              </div>
              {/* ------ Graph Section ---- */}
              <div className={style.deviceInfoCardsContainer}>
                <div className={style.deviceInfoCard}>
                  <p className={style.title}>Voltage</p>
                  <p className={style.value}>{deviceInfo.voltage} V </p>
                </div>
                <div
                  className={style.deviceInfoCard}
                  style={
                    deviceInfo.current > 0
                      ? { backgroundColor: '#ff9a00' }
                      : { backgroundColor: 'white' }
                  }
                >
                  <p className={style.title}>Current</p>
                  <p className={style.value}>{deviceInfo.current} Amp </p>
                </div>
              </div>
            </div>
            <div className={style.btnContainer}>
              <button
                className={style.offBtn}
                // disabled={deviceInfo.is_motor_running ? false : true}
                onClick={switchOffMoter}
                style={
                  deviceInfo.is_motor_running
                    ? { backgroundColor: 'red', color: 'white' }
                    : { backgroundColor: 'gray' }
                }
              >
                OFF
              </button>
              <button
                className={style.onBtn}
                // disabled={deviceInfo.is_motor_running ? true : false}
                onClick={runMotor}
                style={
                  deviceInfo.is_motor_running
                    ? { backgroundColor: 'gray' }
                    : { backgroundColor: 'green', color: 'white' }
                }
              >
                ON
              </button>
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <p>Loading...</p>
          </div>
        )}
      </div>
      {isLoading && (
        <div className={style.popup}>
          <div className={style.loader}></div>
        </div>
      )}
    </div>
  );
};

export default SpmDevice;
