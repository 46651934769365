import React, { useContext } from 'react';
import style from './index.module.css';

import { Context } from '../../../App';

import { BiHomeAlt2 } from 'react-icons/bi';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { TbManualGearbox, TbSettings } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const BottomNav = () => {
  const { isLogin } = useContext(Context);

  return (
    <div className={style.bottomNav}>
      <Link to="/">
        <BiHomeAlt2 size={30} />
      </Link>

      {/* <Link to="/devices">
        <MdOutlineDeviceHub size={30} />
      </Link> */}
      <Link to="/devices">
        <TbManualGearbox size={30} />
      </Link>

      <Link to="/settings">
        <TbSettings size={30} />
      </Link>

      <Link to={isLogin ? '/account' : '/auth/login'}>
        <MdOutlineAccountCircle size={30} />
      </Link>
    </div>
  );
};

export default BottomNav;
