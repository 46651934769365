// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';
import axiosClient from './apiClient/axiosClient';
import { getCookie } from './utils/ManageCookies';

const firebaseConfig = {
  apiKey: 'AIzaSyCFmFBKbthg2jKxvW9EUiafhpd-oXrzT04',
  authDomain: 'aadesh-iot.firebaseapp.com',
  projectId: 'aadesh-iot',
  storageBucket: 'aadesh-iot.appspot.com',
  messagingSenderId: '1083135534500',
  appId: '1:1083135534500:web:9055bf66fc1d5963aacd31',
  measurementId: 'G-ERRTV2VGHE',
};

export async function requestPermission() {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    // Generate Token
    let token = '';
    try {
      token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
    } catch (err) {}
    console.log('Token Gen', token);
    // Send this token  to server ( db) and also store the identity of the user
    if (token) {
      const res = await axiosClient.registerNotification({
        token: token,
        refresh_token: getCookie('refresh_token'),
      });
    }
  } else if (permission === 'denied') {
    alert('You denied for the notification');
  }
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
