import React from 'react';
import style from './index.module.css';

const NotCompitable = () => {
  return (
    <div>
      <p>This App is Not compatiable for Desktop and Teblet Devices</p>
    </div>
  );
};

export default NotCompitable;
