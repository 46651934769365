import React, { useContext, useState } from 'react';
import style from './index.module.css';
import { Link } from 'react-router-dom';
import axiosClient from '../../apiClient/axiosClient';
import { setCookie } from '../../utils/ManageCookies';
import { Context } from '../../App';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { setIsLogin } = useContext(Context);

  const login = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosClient.login({ email, password });
      // console.log(data);
      setCookie('access_token', data.access_token);
      setCookie('refresh_token', data.refresh_token);
      axiosClient.setAuthHeader();
      axiosClient.setFormDataAuthHeader();
      setIsLogin(true);
    } catch (err) {
      console.log(err);
      if (err.response) {
        alert(err.response?.data?.message);
        return;
      }
      alert(`Something Went Wrong ${err.message}`);
    }
  };
  return (
    <div className={style.bgImage}>
      <div className={style.container}>
        <div>
          <p className={style.welcome}>Welcome to</p>
          <p className={style.brandName}>IoT Hub!</p>
          <p className={style.brandMsg}>Explore the latest stocks & track it</p>
        </div>
        <form onSubmit={login}>
          <div className={style.inpCont}>
            <input
              required
              type="email"
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              required
              type="password"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={style.linkBtnCont}>
            <Link className={style.fortext} to="#">
              Forgot Password
            </Link>
            <button type="submit">Login</button>
            <p>
              <span>or </span>
              <Link to="#">Sign up</Link>
            </p>
          </div>
        </form>
        <p className={style.term}>
          <Link to="#">Terms & Conditions </Link>
          <span>apply</span>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
