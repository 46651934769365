import React, { useState, useEffect, createContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// ------------- Pages ------------
import HomePage from './pages/Home';
import BottomNav from './components/app/BottomNav';
import Appbar from './components/app/Appbar';
import LoginPage from './pages/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import NotCompitable from './components/app/NotCompitable';
import { getCookie } from './utils/ManageCookies';
import axiosClient from './apiClient/axiosClient';
import AccountPage from './pages/Account';
import SpmDevice from './pages/Devices/Spm';
import { requestPermission } from './firebase';
export const Context = createContext();

const App = () => {
  const location = useLocation();

  requestPermission();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 512);

  const [isLogin, setIsLogin] = useState(false);
  const [isSnackbarShow, setIsSnackbar] = useState(false);
  const [SnackbarMsg, setSnackbarMgs] = useState({
    type: '',
    message: '',
  });
  const [redirectedPath, setRedirectedPath] = useState('');
  const [accessToken] = useState(() => getCookie('access_token'));

  useEffect(() => {
    if (accessToken) {
      console.log('Access Token Found');
      setIsLogin(true);
      axiosClient.setAuthHeader();
      axiosClient.setFormDataAuthHeader();
    }
  }, [accessToken]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 512);
    };

    window.addEventListener('resize', handleResize);

    setRedirectedPath(location.pathname);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Context.Provider
      value={{ isLogin, setIsLogin, setIsSnackbar, setSnackbarMgs }}
    >
      <div
        style={{
          backgroundColor: '#9BD1E5',
          minHeight: 'calc(100vh - 60px)',
          marginBottom: '60px',
        }}
      >
        {isMobile ? (
          <>
            <Appbar />
            <Routes>
              {/* Non-Protected Route Goes Here */}
              <Route
                path="/auth/login"
                element={
                  isLogin && redirectedPath !== '/auth/login' ? (
                    <Navigate to={redirectedPath} />
                  ) : (
                    <>{!isLogin ? <LoginPage /> : <Navigate to="/" />}</>
                  )
                }
              />

              {/* Prtected Routes Goes Here */}
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/devices/spm/:id" element={<SpmDevice />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="*" element={<h2>Page Not Found</h2>} />
              </Route>
            </Routes>
            <BottomNav />
          </>
        ) : (
          <NotCompitable />
        )}
      </div>
    </Context.Provider>
  );
};

export default App;
