import axios from 'axios';
import { getCookie } from '../utils/ManageCookies';

export const baseURL = 'https://api.iot.inflection.org.in';

let apiClient = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    authorization: `Bearer ${getCookie('access_token')}`,
    Accept: 'application/json',
  },
  // validateStatus: (status) => {
  //   return status < 600; // Reject only if the status code is greater than or equal to 600
  // },
});

const setAuthHeader = () => {
  apiClient = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      authorization: `Bearer ${getCookie('access_token')}`,
    },
  });
};

// ---------------- Form Data axios configurations -----------
let formDataApiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${getCookie('access_token')}`,
  },
  timeout: 60000,
});

const setFormDataAuthHeader = () => {
  formDataApiClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getCookie('access_token')}`,
    },
    timeout: 60000,
  });
};

// ------------------ User -----------------------

const login = (payload) => {
  return apiClient.post('/users/login', payload);
};

// ------------------- Device ------------------------

const fetchMyDevices = () => {
  return apiClient.get('/devices/my');
};

const fetchSingleDeviceStatus = (id) => {
  return apiClient.get(`/devices/spm/cmd/${id}`);
};

const sendCommand = (id, payload) => {
  return apiClient.post(`/devices/spm/cmd/${id}`, payload);
};
const registerNotification = (payload) => {
  return apiClient.post('/users/notifications', payload);
};

// eslint-disable-next-line
export default {
  setAuthHeader,
  setFormDataAuthHeader,
  login,
  fetchMyDevices,
  fetchSingleDeviceStatus,
  sendCommand,
  registerNotification,
};
