import React, { useEffect, useState } from 'react';
import style from './index.module.css';
import axiosClient from '../../apiClient/axiosClient';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState([]);

  const navigate = useNavigate();

  const getMyDevices = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.fetchMyDevices();
      // console.log(data);
      setDevices(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMyDevices();
  }, []);

  return (
    <div className={style.bgImage}>
      {!isLoading ? (
        <div className={style.container}>
          <p className={style.title}>Devices</p>
          <div className={style.cardContainer}>
            {devices.length > 0 &&
              devices.map((device) => (
                <div
                  key={device.device_id}
                  className={style.card}
                  onClick={() => navigate(`/devices/spm/${device.device_id}`)}
                >
                  <p
                    style={{
                      fontWeight: '500',
                      fontSize: '12px',
                      color: 'gray',
                      textAlign: 'end',
                    }}
                  >
                    {device.device_firmware}
                  </p>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#00497a',
                      textTransform: 'uppercase',
                    }}
                  >
                    {device.device_model}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '20px',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: '600',
                        fontSize: '12px',
                        color: 'gray',
                      }}
                    >
                      Device ID
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {device.device_id}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default HomePage;
