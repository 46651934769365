import React, { useContext } from 'react';
import style from './index.module.css';
import { deleteCookie } from '../../utils/ManageCookies';
import { Context } from '../../App';

const AccountPage = () => {
  const { setIsLogin } = useContext(Context);

  const logout = () => {
    deleteCookie('access_token');
    deleteCookie('refresh_token');
    setIsLogin(false);
  };
  return (
    <div>
      <div className={style.container}>
        <p>Account Profile Page</p>
        <button className={style.logoutBtn} onClick={logout}>
          Log out
        </button>
      </div>
    </div>
  );
};

export default AccountPage;
