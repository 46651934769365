import React from 'react';
import style from './index.module.css';

import { BsList } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Appbar = () => {
  return (
    <header>
      <nav>
        <BsList size={25} />
        <Link to="/" className={style.logo}>
          IoT HUB
        </Link>
      </nav>
    </header>
  );
};

export default Appbar;
